<template>
  <svg
    class="SettingsIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_607_19864"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" />
    </mask>
    <g mask="url(#mask0_607_19864)">
      <path
        d="M9.69998 21.5L9.29998 18.45C9.03331 18.3667 8.76264 18.2417 8.48798 18.075C8.21264 17.9083 7.95831 17.7333 7.72498 17.55L4.89998 18.75L2.59998 14.75L5.04998 12.9C5.01664 12.75 4.99598 12.6 4.98798 12.45C4.97931 12.3 4.97498 12.15 4.97498 12C4.97498 11.8667 4.97931 11.725 4.98798 11.575C4.99598 11.425 5.01664 11.2667 5.04998 11.1L2.59998 9.25L4.89998 5.275L7.72498 6.45C7.95831 6.26667 8.21264 6.096 8.48798 5.938C8.76264 5.77933 9.03331 5.65 9.29998 5.55L9.69998 2.5H14.3L14.7 5.55C15 5.66667 15.2706 5.79567 15.512 5.937C15.754 6.079 16 6.25 16.25 6.45L19.1 5.275L21.4 9.25L18.925 11.125C18.9583 11.2917 18.975 11.4417 18.975 11.575V12C18.975 12.1333 18.9706 12.2707 18.962 12.412C18.954 12.554 18.9333 12.7167 18.9 12.9L21.35 14.75L19.05 18.75L16.25 17.55C16 17.75 15.746 17.925 15.488 18.075C15.2293 18.225 14.9666 18.35 14.7 18.45L14.3 21.5H9.69998ZM11 20H12.975L13.325 17.325C13.8416 17.1917 14.3083 17 14.725 16.75C15.1416 16.5 15.55 16.1833 15.95 15.8L18.425 16.85L19.425 15.15L17.25 13.525C17.3333 13.2583 17.3876 13 17.413 12.75C17.4376 12.5 17.45 12.25 17.45 12C17.45 11.7333 17.4376 11.4793 17.413 11.238C17.3876 10.996 17.3333 10.75 17.25 10.5L19.425 8.85L18.45 7.15L15.925 8.2C15.5916 7.85 15.1916 7.53733 14.725 7.262C14.2583 6.98733 13.7916 6.79167 13.325 6.675L13 4H11.025L10.675 6.675C10.175 6.79167 9.70831 6.975 9.27498 7.225C8.84164 7.475 8.42498 7.79167 8.02498 8.175L5.54998 7.15L4.57498 8.85L6.72498 10.45C6.64164 10.7 6.58331 10.95 6.54998 11.2C6.51664 11.45 6.49998 11.7167 6.49998 12C6.49998 12.2667 6.51664 12.525 6.54998 12.775C6.58331 13.025 6.64164 13.275 6.72498 13.525L4.57498 15.15L5.54998 16.85L8.02498 15.8C8.40831 16.1833 8.81664 16.5 9.24998 16.75C9.68331 17 10.1583 17.1917 10.675 17.325L11 20Z"
      />
      <path
        d="M14.668 11.9999C14.668 13.4734 13.4735 14.6679 12 14.6679C10.5265 14.6679 9.33197 13.4734 9.33197 11.9999C9.33197 10.5264 10.5265 9.33191 12 9.33191C13.4735 9.33191 14.668 10.5264 14.668 11.9999Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "SettingsIcon",
};
</script>

<style lang="scss" scoped>
.SettingsIcon {
  width: 100%;
  height: auto;
  fill: currentcolor;
}
</style>
