<template>
  <svg
    class="LeftIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 17.65L8.34998 12L14 6.35001L15.05 7.40001L10.45 12L15.05 16.6L14 17.65Z"
    />
  </svg>
</template>

<script>
export default {
  name: "LeftIcon",
};
</script>

<style lang="scss" scoped>
.LeftIcon {
  width: 100%;
  height: auto;
  fill: currentcolor;
}
</style>
