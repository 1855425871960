<template>
  <svg
    class="UndoIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.05 18.75H7.20005V17.25H14.05C15.1667 17.25 16.1374 16.896 16.962 16.188C17.7874 15.4793 18.2 14.5833 18.2 13.5C18.2 12.4167 17.7874 11.5207 16.962 10.812C16.1374 10.104 15.1667 9.75001 14.05 9.75001H7.15005L10 12.6L8.95005 13.65L4.30005 9.00001L8.95005 4.35001L10 5.40001L7.15005 8.25001H14.05C15.5834 8.25001 16.9084 8.75001 18.025 9.75001C19.1417 10.75 19.7 12 19.7 13.5C19.7 15 19.1417 16.25 18.025 17.25C16.9084 18.25 15.5834 18.75 14.05 18.75Z"
    />
  </svg>
</template>

<script>
export default {
  name: "UndoIcon",
};
</script>

<style lang="scss" scoped>
.UndoIcon {
  width: 100%;
  height: auto;
  fill: currentcolor;
}
</style>
