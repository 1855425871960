<template>
  <!-- large固定 optionのところはpropsの変更必要 -->
  <input
    class="Checkbox"
    type="checkbox"
    @click="onchange"
    :checked="checked"
    :value="value"
    :aria-label="label"
  />
</template>

<script setup lang="ts">
// 設定
interface Props {
  isChecked?: boolean;
  label: string;
  value?: string;
}
const props = withDefaults(defineProps<Props>(), {
  isChecked: false,
});

const checked = ref(props.isChecked);

const emit = defineEmits<{
  (e: "update:isChecked", value: boolean): void;
}>();

// method
const onchange = (e: Event) => {
  const checkedValue = (e.target as HTMLInputElement).checked;
  emit("update:isChecked", checkedValue);
};
</script>

<style lang="scss" scoped>
$checkOn: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjc5MzQgMTUuNzAxNEwxNy41MTg0IDguOTc2NDRMMTYuNDY4NCA3LjkyNjQ0TDEwLjc5MzQgMTMuNjAxNEw3Ljk0MzM2IDEwLjc1MTRMNi44OTMzNiAxMS44MDE0TDEwLjc5MzQgMTUuNzAxNFpNMy42OTMzNiAyMC4zNTE0VjMuMzUxNDRIMjAuNjkzNFYyMC4zNTE0SDMuNjkzMzZaIiBmaWxsPSIjMjY2N0Q3Ii8+Cjwvc3ZnPgo=";
$checkOff: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuNSAyMC41VjMuNUgyMC41VjIwLjVIMy41Wk01IDE5SDE5VjVINVYxOVoiIGZpbGw9IiMwODE5MkQiLz4KPC9zdmc+Cg==";
$check-size: $service-unit * 6;

.Checkbox {
  position: relative;
  width: $check-size;
  height: $check-size;
  padding: 0;
  margin: 0;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: $check-size;
    height: $check-size;
    cursor: pointer;
    content: "";
    background: url($checkOff) no-repeat center / $check-size $check-size;
  }

  &:checked::after {
    background: url($checkOn) no-repeat center / $check-size $check-size;
  }

  &:focus {
    outline: 2px solid $color-blue-60;
  }
}
</style>
