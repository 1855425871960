<template>
  <svg
    class="SpecteeLogoBlack"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <rect width="28" height="28" style="fill: #000" />
    <g>
      <g>
        <polygon
          points="19.89 8.86 15.18 6.98 21.52 6.98 21.52 12.95 19.89 8.86"
          style="fill: #fff"
        />
        <polygon
          points="19.89 8.86 19.89 8.86 21.52 6.98 21.52 12.95 19.89 8.86"
          style="fill: #bfbfbf"
        />
      </g>
      <path
        d="M12.38,10.32c-1.91,0-3.34,.94-3.34,2.68,0,3.17,6.12,3.08,6.12,5.25,0,.93-.59,1.39-2.03,1.39h-4.42l-1.19,1.34h5.91c2.15,0,3.37-1.14,3.37-2.85,0-3.24-6.12-3.24-6.12-5.21,0-.82,.57-1.26,1.48-1.26h4.44v-1.33h-4.22Z"
        style="fill: #fff"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "SpecteeLogoBlack",
};
</script>

<style lang="scss" scoped>
.SpecteeLogoBlack {
  width: 100%;
  height: auto;
  border-radius: $service-unit;
}
</style>
