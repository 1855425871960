<template>
  <svg
    class="ErrorFillIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 7.075V13.075H11V7.075H13ZM8.288 20.75C9.446 21.25 10.6833 21.5 12 21.5C13.3167 21.5 14.5543 21.25 15.713 20.75C16.871 20.25 17.875 19.575 18.725 18.725C19.575 17.875 20.25 16.8707 20.75 15.712C21.25 14.554 21.5 13.3167 21.5 12C21.5 10.6833 21.25 9.44567 20.75 8.287C20.25 7.129 19.575 6.125 18.725 5.275C17.875 4.425 16.871 3.75 15.713 3.25C14.5543 2.75 13.3167 2.5 12 2.5C10.6833 2.5 9.446 2.75 8.288 3.25C7.12933 3.75 6.125 4.425 5.275 5.275C4.425 6.125 3.75 7.129 3.25 8.287C2.75 9.44567 2.5 10.6833 2.5 12C2.5 13.3167 2.75 14.554 3.25 15.712C3.75 16.8707 4.425 17.875 5.275 18.725C6.125 19.575 7.12933 20.25 8.288 20.75ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ErrorFillIcon",
};
</script>

<style lang="scss" scoped>
.ErrorFillIcon {
  width: 100%;
  height: auto;
  fill: currentcolor;
}
</style>
