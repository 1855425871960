<template>
  <svg
    class="UpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.4 15.05L6.35 13.975L12 8.32501L17.65 13.975L16.6 15.05L12 10.45L7.4 15.05Z"
    />
  </svg>
</template>

<script>
export default {
  name: "UpIcon",
};
</script>

<style lang="scss" scoped>
.UpIcon {
  width: 100%;
  height: auto;
  fill: currentcolor;
}
</style>
