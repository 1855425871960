<template>
  <svg
    class="SuccessIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.932 16.137L17.657 9.41196L16.607 8.36196L10.932 14.037L8.08203 11.187L7.03203 12.237L10.932 16.137ZM12.357 21.387C11.0404 21.387 9.80303 21.137 8.64503 20.637C7.48636 20.137 6.48203 19.462 5.63203 18.612C4.78203 17.762 4.10703 16.7576 3.60703 15.599C3.10703 14.441 2.85703 13.2036 2.85703 11.887C2.85703 10.5703 3.10703 9.33263 3.60703 8.17396C4.10703 7.01596 4.78203 6.01196 5.63203 5.16196C6.48203 4.31196 7.48636 3.63696 8.64503 3.13696C9.80303 2.63696 11.0404 2.38696 12.357 2.38696C13.6737 2.38696 14.9114 2.63696 16.07 3.13696C17.228 3.63696 18.232 4.31196 19.082 5.16196C19.932 6.01196 20.607 7.01596 21.107 8.17396C21.607 9.33263 21.857 10.5703 21.857 11.887C21.857 13.2036 21.607 14.441 21.107 15.599C20.607 16.7576 19.932 17.762 19.082 18.612C18.232 19.462 17.228 20.137 16.07 20.637C14.9114 21.137 13.6737 21.387 12.357 21.387ZM12.357 19.887C14.5737 19.887 16.4614 19.108 18.02 17.55C19.578 15.9913 20.357 14.1036 20.357 11.887C20.357 9.6703 19.578 7.78263 18.02 6.22396C16.4614 4.66596 14.5737 3.88696 12.357 3.88696C10.1404 3.88696 8.25303 4.66596 6.69502 6.22396C5.13636 7.78263 4.35703 9.6703 4.35703 11.887C4.35703 14.1036 5.13636 15.9913 6.69502 17.55C8.25303 19.108 10.1404 19.887 12.357 19.887Z"
    />
  </svg>
</template>

<script>
export default {
  name: "SuccessIcon",
};
</script>

<style lang="scss" scoped>
.SuccessIcon {
  width: 100%;
  height: auto;
  fill: currentcolor;
}
</style>
