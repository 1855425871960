<template>
  <svg
    class="SecurityIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 21.1102V9.6102H7.5V7.38982C7.5 6.13982 7.93733 5.07716 8.812 4.20182C9.68733 3.32716 10.75 2.88982 12 2.88982C13.25 2.88982 14.3127 3.32716 15.188 4.20182C16.0627 5.07716 16.5 6.13982 16.5 7.38982V9.6102H19.5V21.1102H4.5ZM9 9.6102H15V7.38982C15 6.55649 14.7083 5.84816 14.125 5.26482C13.5417 4.68149 12.8333 4.38982 12 4.38982C11.1667 4.38982 10.4583 4.68149 9.875 5.26482C9.29167 5.84816 9 6.55649 9 7.38982V9.6102ZM6 19.6102H18V11.1102H6V19.6102ZM12 16.8602C12.4833 16.8602 12.896 16.6895 13.238 16.3482C13.5793 16.0062 13.75 15.5935 13.75 15.1102C13.75 14.6269 13.5793 14.2142 13.238 13.8722C12.896 13.5309 12.4833 13.3602 12 13.3602C11.5167 13.3602 11.104 13.5309 10.762 13.8722C10.4207 14.2142 10.25 14.6269 10.25 15.1102C10.25 15.5935 10.4207 16.0062 10.762 16.3482C11.104 16.6895 11.5167 16.8602 12 16.8602Z"
    />
  </svg>
</template>

<script>
export default {
  name: "SecurityIcon",
};
</script>

<style lang="scss" scoped>
.SecurityIcon {
  width: 100%;
  height: auto;
  fill: currentcolor;
}
</style>
