<template>
  <svg
    class="HelpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 17.625C12.2833 17.625 12.521 17.525 12.713 17.325C12.9043 17.125 13 16.8833 13 16.6C13 16.3167 12.9043 16.075 12.713 15.875C12.521 15.675 12.2833 15.575 12 15.575C11.7 15.575 11.4543 15.675 11.263 15.875C11.071 16.075 10.975 16.3167 10.975 16.6C10.975 16.8833 11.071 17.125 11.263 17.325C11.4543 17.525 11.7 17.625 12 17.625ZM11.275 14.025H12.7C12.7 13.5417 12.7667 13.154 12.9 12.862C13.0333 12.5707 13.35 12.175 13.85 11.675C14.3 11.2417 14.6417 10.8373 14.875 10.462C15.1083 10.0873 15.225 9.65 15.225 9.15C15.225 8.28333 14.9167 7.60833 14.3 7.125C13.6833 6.64167 12.95 6.4 12.1 6.4C11.2667 6.4 10.575 6.625 10.025 7.075C9.475 7.525 9.08333 8.05 8.85 8.65L10.15 9.15C10.2667 8.83333 10.471 8.51667 10.763 8.2C11.0543 7.88333 11.4917 7.725 12.075 7.725C12.675 7.725 13.1167 7.88733 13.4 8.212C13.6833 8.53733 13.825 8.89167 13.825 9.275C13.825 9.60833 13.725 9.91667 13.525 10.2C13.325 10.4833 13.075 10.7583 12.775 11.025C12.125 11.6083 11.7127 12.0793 11.538 12.438C11.3627 12.796 11.275 13.325 11.275 14.025ZM12 21.5C10.6833 21.5 9.45 21.25 8.3 20.75C7.15 20.25 6.14567 19.575 5.287 18.725C4.429 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.429 6.125 5.287 5.275C6.14567 4.425 7.15 3.75 8.3 3.25C9.45 2.75 10.6833 2.5 12 2.5C13.3333 2.5 14.575 2.75 15.725 3.25C16.875 3.75 17.8793 4.425 18.738 5.275C19.596 6.125 20.271 7.129 20.763 8.287C21.2543 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.2543 14.554 20.763 15.712C20.271 16.8707 19.596 17.875 18.738 18.725C17.8793 19.575 16.875 20.25 15.725 20.75C14.575 21.25 13.3333 21.5 12 21.5ZM12 20C14.2333 20 16.125 19.221 17.675 17.663C19.225 16.1043 20 14.2167 20 12C20 9.78333 19.225 7.89567 17.675 6.337C16.125 4.779 14.2333 4 12 4C9.8 4 7.91667 4.779 6.35 6.337C4.78333 7.89567 4 9.78333 4 12C4 14.2167 4.78333 16.1043 6.35 17.663C7.91667 19.221 9.8 20 12 20Z"
    />
  </svg>
</template>

<script>
export default {
  name: "HelpIcon",
};
</script>

<style lang="scss" scoped>
.HelpIcon {
  width: 100%;
  height: auto;
  fill: currentcolor;
}
</style>
