<template>
  <svg
    class="NotificationsIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.25 18.875V17.375H6.25V10.125C6.25 8.79165 6.66667 7.59565 7.5 6.53699C8.33333 5.47899 9.41667 4.79999 10.75 4.49999V2.54999H13.25V4.49999C14.5833 4.79999 15.6667 5.47899 16.5 6.53699C17.3333 7.59565 17.75 8.79165 17.75 10.125V17.375H19.75V18.875H4.25ZM12 21.8C11.5 21.8 11.075 21.625 10.725 21.275C10.375 20.925 10.2 20.5 10.2 20H13.8C13.8 20.5 13.625 20.925 13.275 21.275C12.925 21.625 12.5 21.8 12 21.8ZM7.75 17.375H16.25V10.125C16.25 8.94165 15.8333 7.93732 15 7.11199C14.1667 6.28732 13.1667 5.87499 12 5.87499C10.8333 5.87499 9.83333 6.28732 9 7.11199C8.16667 7.93732 7.75 8.94165 7.75 10.125V17.375Z"
    />
  </svg>
</template>

<script>
export default {
  name: "NotificationsIcon",
};
</script>

<style lang="scss" scoped>
.NotificationsIcon {
  width: 100%;
  height: auto;
  fill: currentcolor;
}
</style>
