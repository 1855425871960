<template>
  <svg
    class="OtherIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.3 20.75C9.45 21.25 10.6833 21.5 12 21.5C13.3333 21.5 14.575 21.25 15.725 20.75C16.875 20.25 17.8793 19.575 18.738 18.725C19.596 17.875 20.271 16.8707 20.763 15.712C21.2543 14.554 21.5 13.3167 21.5 12C21.5 10.6833 21.2543 9.44567 20.763 8.287C20.271 7.129 19.596 6.125 18.738 5.275C17.8793 4.425 16.875 3.75 15.725 3.25C14.575 2.75 13.3333 2.5 12 2.5C10.6833 2.5 9.45 2.75 8.3 3.25C7.15 3.75 6.14567 4.425 5.287 5.275C4.429 6.125 3.75 7.129 3.25 8.287C2.75 9.44567 2.5 10.6833 2.5 12C2.5 13.3167 2.75 14.554 3.25 15.712C3.75 16.8707 4.429 17.875 5.287 18.725C6.14567 19.575 7.15 20.25 8.3 20.75ZM17.675 17.663C16.125 19.221 14.2333 20 12 20C9.8 20 7.91667 19.221 6.35 17.663C4.78333 16.1043 4 14.2167 4 12C4 9.78333 4.78333 7.89567 6.35 6.337C7.91667 4.779 9.8 4 12 4C14.2333 4 16.125 4.779 17.675 6.337C19.225 7.89567 20 9.78333 20 12C20 14.2167 19.225 16.1043 17.675 17.663ZM7.13581 12.885C7.37859 13.1283 7.67359 13.25 8.02081 13.25C8.36804 13.25 8.66331 13.1283 8.90665 12.885C9.14942 12.6422 9.27081 12.3472 9.27081 12C9.27081 11.6528 9.14942 11.3578 8.90665 11.115C8.66331 10.8717 8.36804 10.75 8.02081 10.75C7.67359 10.75 7.37859 10.8717 7.13581 11.115C6.89248 11.3578 6.77081 11.6528 6.77081 12C6.77081 12.3472 6.89248 12.6422 7.13581 12.885ZM11.115 12.885C11.3578 13.1283 11.6528 13.25 12 13.25C12.3472 13.25 12.6422 13.1283 12.885 12.885C13.1283 12.6422 13.25 12.3472 13.25 12C13.25 11.6528 13.1283 11.3578 12.885 11.115C12.6422 10.8717 12.3472 10.75 12 10.75C11.6528 10.75 11.3578 10.8717 11.115 11.115C10.8716 11.3578 10.75 11.6528 10.75 12C10.75 12.3472 10.8716 12.6422 11.115 12.885ZM15.0941 12.885C15.3369 13.1283 15.6319 13.25 15.9791 13.25C16.3264 13.25 16.6216 13.1283 16.865 12.885C17.1078 12.6422 17.2291 12.3472 17.2291 12C17.2291 11.6528 17.1078 11.3578 16.865 11.115C16.6216 10.8717 16.3264 10.75 15.9791 10.75C15.6319 10.75 15.3369 10.8717 15.0941 11.115C14.8508 11.3578 14.7291 11.6528 14.7291 12C14.7291 12.3472 14.8508 12.6422 15.0941 12.885Z"
    />
  </svg>
</template>

<script>
export default {
  name: "OtherIcon",
};
</script>

<style lang="scss" scoped>
.OtherIcon {
  width: 100%;
  height: auto;
  fill: currentcolor;
}
</style>
