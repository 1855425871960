<template>
  <div class="QuestionnaireCheckBox">
    <p v-if="item.errorMessage" class="QuestionnaireCheckBox__error">
      {{ item.errorMessage }}
    </p>
    <ul class="QuestionnaireCheckBox__list">
      <li
        class="QuestionnaireCheckBox__item"
        v-for="(option, index) in item.options"
        :key="`CheckBox-${index}`"
      >
        <label class="QuestionnaireCheckBox__menuLabel">
          <Checkbox
            class="QuestionnaireCheckBox__checkbox"
            :label="option"
            :isChecked="indexs[index]"
            :value="option"
            @update:isChecked="onchange(index)"
          />
          <span class="QuestionnaireCheckBox__label" v-if="option">{{
            option
          }}</span>
        </label>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import Checkbox from "@/components/Atoms/Checkbox/index.vue";

const props = defineProps(["item"]);
const emit = defineEmits(["update:value"]);

// checked
const indexs = ref(new Array(props.item.options.length).fill(false));
const index = ref(0);

// method
const onchange = (val: number) => {
  indexs.value[val] = !indexs.value[val];
  index.value = val;
  emit("update:value", toRaw(indexs.value));
};
</script>

<style lang="scss" scoped>
.QuestionnaireCheckBox {
  &__error {
    font-size: $service-font-size-pp;
    line-height: $service-line-height-mf;
    color: $color-red-70;
  }

  &__error + &__list {
    margin-top: $service-unit * 3;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: $service-unit * 3;
    }

    &:hover {
      background-color: $color-gray-20;
    }
  }

  & &__checkBox {
    position: absolute;
    top: 50%;
    left: $service-unit * 4;
    transform: translateY(-50%);
  }

  &__menuLabel {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: $service-unit * 12;
    padding: 0 $service-unit * 4;
    cursor: pointer;
    border: 1px solid $color-gray-50;
    border-radius: $service-border-radius;
  }

  &__checkBox:checked + &__menuLabel {
    background-color: $color-blue-10;
    border: 1px solid $color-blue-60;

    &:hover {
      background-color: $color-blue-10;
    }
  }

  &__label {
    margin-left: $service-unit * 2;
    font-size: $service-font-size-mp;
    font-weight: bold;
    line-height: $service-line-height-mf;
    color: $color-gray-80;
  }
}
</style>
