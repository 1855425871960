<template>
  <svg
    class="DropDownIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 14.65L7.60001 10.25H16.4L12 14.65Z" />
  </svg>
</template>

<script>
export default {
  name: "DropDownIcon",
};
</script>

<style lang="scss" scoped>
.DropDownIcon {
  width: 100%;
  height: auto;
  fill: currentcolor;
}
</style>
