import { AxiosRequestConfig } from "axios";
import { Router, RouteLocationNormalizedLoaded } from "vue-router";

// フォーム表示用の型
export type SurveyItem = Omit<ResponseContent, "title" | "options"> & {
  title: FormTitle;
  options?: FormOption[];
};

// APIで扱うデータの型
type ResponseContent = {
  title: SettingResponseTitleKey;
  type: "text" | "radio" | "checkbox";
  options?: string[];
  required: boolean;
  condition: [] | [string, string[]];
  placeholder?: string;
  value?: string;
  isError?: boolean;
  errorMessage?: string;
};

// APIで扱うフォーム設問のキー
type SettingResponseTitleKey = keyof typeof formTitleMap;

type FormTitle = {
  key: SettingResponseTitleKey; // APIで扱うフォーム設問のキー
  displayName: string; // 表示用の値
};
export type FormOption = {
  key: keyof typeof formOptionMap; // APIで扱うフォーム選択肢のキー
  displayName: string; // 表示用の値
};

// フォーム表示用の値の定義
export const formTitleMap: Record<string, FormTitle> = {
  respondent: { key: "回答者", displayName: "回答者（Respondent）" },
  impactOnSpot: {
    key: "拠点の被害",
    displayName: "拠点の被害（Impact on Spot）",
  },
  humanSuffering: {
    key: "人的被害",
    displayName: "人的被害（Human Suffering）",
  },
  propertyDamage: {
    key: "物的被害",
    displayName: "物的被害（Property Damage）",
  },
  productionDisruption: {
    key: "生産ラインの被害",
    displayName: "生産ラインの被害（Production Disruption）",
  },
  shippingDelays: {
    key: "出荷の遅延",
    displayName: "出荷の遅延（Shipping Delays）",
  },
  statusReport: { key: "状況報告", displayName: "状況報告（Status Report）" },
};
export const formOptionMap: Record<string, FormOption> = {
  yes: { key: "あり", displayName: "あり（Yes）" },
  no: { key: "なし", displayName: "なし（No）" },
  underReview: { key: "確認中", displayName: "確認中（Under Review）" },
};

const errorCode = {
  expired: 410,
  idNotFound: 404,
  noDifference: 400,
  serverError: 500,
};

export function useSurveyResponse(
  router: Router,
  route: RouteLocationNormalizedLoaded
) {
  const surveyItems: Ref<SurveyItem[] | []> = ref([]);
  const isFinished = ref(false);
  const spotName = ref("");
  const isAnswered = ref(false);

  // token
  const token = route.query.token as string;
  const id = ref("");

  // api
  const url = "/surveyResponse";
  const VITE_APP_REST_API_URL = import.meta.env.VITE_APP_REST_API_URL;
  const VITE_APP_REST_API_KEY = import.meta.env.VITE_APP_REST_API_KEY;
  const requestConfigGet: AxiosRequestConfig = {
    method: "GET",
    headers: { "x-api-key": VITE_APP_REST_API_KEY },
  };
  const requestConfigPost: AxiosRequestConfig = {
    method: "POST",
    headers: { "x-api-key": VITE_APP_REST_API_KEY },
  };
  const instance = axios.create({ baseURL: VITE_APP_REST_API_URL });

  onMounted(async () => {
    init();
  });

  // 初期処理
  const init = async () => {
    id.value = token;

    // アンケートの設問を取得
    const idEncoded = encodeURIComponent(id.value);
    const urlGet = `${url}?&id=${idEncoded}`;
    const { execute } = useAxios(urlGet, requestConfigGet, instance);
    const { data, error } = await execute();

    // エラー時
    if (error.value) {
      if (
        error.value.response?.data.error.code == errorCode.expired ||
        error.value.response?.data.error.code == errorCode.idNotFound
      ) {
        router.push({ name: "error" });
      }
    }

    // フォーム表示用の値を作成し、格納
    surveyItems.value = data.value.settingResponse.map(
      (item: ResponseContent) => {
        const formTitle: FormTitle | undefined = Object.values(
          formTitleMap
        ).find((i) => i.key === item.title);
        const formOptions: FormOption[] | undefined = item.options?.map(
          (option) => {
            const data = Object.values(formOptionMap).find(
              (i) => i.key === option
            );
            return data ?? { key: option, displayName: option };
          }
        );

        return {
          ...item,
          title: formTitle ?? { key: item.title, displayName: item.title },
          options: formOptions,
        } as SurveyItem;
      }
    );
    spotName.value = data.value.meta.spotName;

    // 既に回答済みの場合は回答済みの文言を出す
    isAnswered.value = surveyItems.value.some((i) => i.value);

    // loading画面終了
    isFinished.value = true;
  };

  const isSendLoading = ref(false);
  const isModalOpen = ref(false);

  // 登録
  const submit = async () => {
    isSendLoading.value = true;
    // フォーム表示用の値をDB保存用に変換
    const responseContents: ResponseContent[] = surveyItems.value.map(
      (item: SurveyItem) => {
        return {
          ...item,
          title: item.title.key,
          options: item.options?.map((option) => option.key),
        };
      }
    );
    // 回答内容ごとに送信するデータを整形
    const settingResponse = responseContents.map((item: ResponseContent) => {
      if (item.title === formTitleMap.impactOnSpot.key) {
        return {
          title: item.title,
          value: item.value,
        };
      } else if (
        // NOTE:拠点の被害がありを選択時は全ての入力値を渡す
        responseContents.find((i) => i.title === formTitleMap.impactOnSpot.key)
          ?.value === formOptionMap.yes.key
      ) {
        return {
          title: item.title,
          value: item.value,
        };
      } else {
        // NOTE:拠点の被害がなし or 確認中を選択時はvalueは含めない
        if (item.type == "radio") {
          return {
            title: item.title,
          };
        }
        // NOTE:初回入力以降は入力値を渡す、初回入力があるまではvalueは含めない
        if (item.type == "text") {
          return {
            title: item.title,
            value: item.value,
          };
        }
      }
    });

    const body = {
      id: id.value,
      settingResponse: settingResponse,
    };

    const { execute } = useAxios(url, requestConfigPost, instance, {
      immediate: false,
    });
    const { error } = await execute({ data: body });

    if (error.value) {
      isSendLoading.value = false;
      // 回答期限切れ、UUIDが見つからない
      if (
        error.value.response?.status == errorCode.expired ||
        error.value.response?.status == errorCode.idNotFound
      ) {
        router.push({ name: "error" });
      }
      // 回答が前回と同じ場合
      if (error.value.response?.status == errorCode.noDifference) {
        // モーダルを表示
        isAnswered.value = true;
        isModalOpen.value = true;
        return;
      }
      if (error.value.response?.status == errorCode.serverError) {
        // 送信失敗モーダルを表示
        isAnswered.value = false;
        isModalOpen.value = true;
        return;
      }
    } else {
      router.push({ name: "sendComplete" });
    }
  };

  return {
    surveyItems,
    submit,
    isFinished,
    isSendLoading,
    isModalOpen,
    isAnswered,
    spotName,
  };
}
