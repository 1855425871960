<template>
  <svg
    class="RadioOffIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4868_17308)">
      <path
        d="M12.4004 2C6.88039 2 2.40039 6.48 2.40039 12C2.40039 17.52 6.88039 22 12.4004 22C17.9204 22 22.4004 17.52 22.4004 12C22.4004 6.48 17.9204 2 12.4004 2ZM12.4004 20C7.98039 20 4.40039 16.42 4.40039 12C4.40039 7.58 7.98039 4 12.4004 4C16.8204 4 20.4004 7.58 20.4004 12C20.4004 16.42 16.8204 20 12.4004 20Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_4868_17308">
        <rect width="24" height="24" transform="translate(0.400391)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "RadioOffIcon",
};
</script>

<style lang="scss" scoped>
.RadioOffIcon {
  width: 100%;
  height: auto;
  fill: currentcolor;
}
</style>
