<template>
  <div class="Textarea">
    <p v-if="isError" class="Textarea__error">
      {{ errorText }}
    </p>
    <textarea
      class="Textarea__textBox"
      :placeholder="placeholder ? placeholder : ''"
      v-model="inputValue"
      @blur="onBlur"
    />
  </div>
</template>

<script setup lang="ts">
// 設定
interface Props {
  value?: string;
  errorText?: string;
  placeholder?: string;
  isError?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "update:value", value?: string): void;
  (e: "blur", value?: string): void;
}>();
// 入力値
const inputValue = computed({
  get: () => props.value,
  set: (val) => {
    emit("update:value", val);
  },
});

const lastValue = ref(props.value);
const onBlur = () => {
  emit("blur", lastValue.value);
};
</script>

<style lang="scss" scoped>
.Textarea {
  &__error {
    font-size: $service-font-size-pp;
    line-height: $service-line-height-mf;
    color: $text-red;
  }

  &__textBox {
    display: block;
    width: 100%;
    min-height: $service-unit * 34;
    padding: $service-unit * 1.5 $service-unit * 4;
    font-family: Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif;
    font-size: $service-font-size-mp;
    line-height: $service-line-height-mf;
    resize: none;
    border: 1px solid $border-primary;
    border-radius: $service-border-radius;

    &::placeholder {
      font-family: Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans",
        sans-serif;
      color: $text-disable;
    }

    &:hover {
      border: 1px solid $border-blue;
    }

    &:focus {
      outline: 2px solid $border-blue;
    }
  }

  &__error + &__textBox {
    margin-top: $service-unit * 3;
  }
}
</style>
