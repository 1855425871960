<template>
  <component
    :is="tagName"
    class="Button"
    :class="[`Button--${size} Button--${color}`]"
    :href="href"
    :disabled="isDisabled"
  >
    <span class="Button__inner">
      <Icon v-if="iconName" class="Button__icon" :iconName="iconName" />
      {{ label }}
    </span>
  </component>
</template>

<script lang="ts" setup>
import Icon from "@/components/Atoms/IconBase/index.vue";
import { computed } from "vue";

interface Props {
  tag: string;
  label: string;
  size: string;
  color: string;
  iconName?: string;
  href?: string;
  isDisabled?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  isDisabled: false,
});

const tagName = computed(() => {
  return props.tag;
});
</script>

<style lang="scss" scoped>
.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  line-height: $service-line-height-mf;
  cursor: pointer;
  border-radius: $service-border-radius;
  box-shadow: $service-shadow-mp;

  &--large {
    height: $service-unit * 10;
    padding: $service-unit * 1.5 $service-unit * 5;
    font-size: $service-font-size-p;
  }

  &--medium {
    height: $service-unit * 9;
    padding: $service-unit * 1.5 $service-unit * 4;
    font-size: $service-font-size-mp;
  }

  &--small {
    height: $service-unit * 8;
    padding: $service-unit * 1.5 $service-unit * 4;
    font-size: $service-font-size-pp;
  }

  &--blue {
    color: $text-white;
    background-color: $blue-primary;

    &:hover {
      background-color: $blue-primary-hover;
    }
  }

  &--black {
    color: $text-white;
    background-color: $black;

    &:hover {
      background-color: $black-hover;
    }
  }

  &--gray {
    color: $text-primary;
    background-color: $gray-secondary;

    &:hover {
      background-color: $gray-secondary-hover;
    }
  }

  &--red {
    position: relative;
    color: $text-white;
    background-color: $red-primary;

    &:hover::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background-color: $red-primary-hover;
      border-radius: $service-border-radius;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    color: $text-disable;
    cursor: inherit;
    background-color: $gray-secondary;

    &::before {
      opacity: 0;
    }
  }

  &__inner {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    margin-right: $service-unit;
  }

  &--blue &__icon,
  &--black &__icon {
    color: $icon-white;
  }

  &--large &__icon,
  &--medium &__icon {
    width: $service-unit * 5;
    height: $service-unit * 5;
  }

  &--small &__icon {
    width: $service-unit * 4;
    height: $service-unit * 4;
  }
}
</style>
